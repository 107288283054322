//
import GUserAccountService from '@/services/api/General/GUserAccount'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-login',
  data() {
    return {
      busy: false,
      gUserAccountService: {},
    }
  },
  methods: {

    /**
     * 
     */
    handleFormReset() {
      this.gUserAccountService = GUserAccountService.toRecovery()
    },

    /**
     * 
     */
    async handleFormSubmit() {
      this.busy = true
      await GUserAccountService.requestResetPassword(this.gUserAccountService).then(this.handleRequestResetPasswordSuccess).catch(this.handleRequestResetPasswordError)
      this.busy = false
    },

    /**
     * 
     */
    handleRequestResetPasswordError(payload) {
      GUserAccountService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleRequestResetPasswordSuccess(payload) {
      GUserAccountService.translate({ response: payload }).then(this.$toast.success)
      this.$router.push('/auth')
    },
  },
  created() {
    this.handleFormReset()
  },
  props: {

    /**
     * 
     */
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },
  }
}